<template>
<div>
  <vs-row vs-justify="left">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('MOTIF.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('MOTIF.HEADER.TEXT')}}</span>
        </p>
        <AddMotifDlg ref="addMotifDlg" v-on:Success="LoadData"></AddMotifDlg>
        <AddMotifcategoryDlg ref="addMotifcategoryDlg" v-on:Success="LoadData"></AddMotifcategoryDlg>
      </vs-card>
    </vs-col>
  </vs-row>
    <vs-row vs-justify="left">
    <vs-col type="flex" vs-justify="left" vs-align="left" vs-lg="6" vs-sm="12" vs-xs="12">
      <vs-card >
        <vs-tabs >
          <vs-tab :label="$t('MOTIF.TAB_MOTIFS')" >
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="AddMotif">{{$t('MOTIF.ADD')}}</vs-button>
              <vs-button class="ml-2 mt-3 mb-2"  type="filled" icon="refresh" @click="OpenConfirmLoadFromWebsite">{{$t('MOTIF.LOAD_FROM_WEBSITE')}}</vs-button>
              <vs-button class="ml-2 mt-3 mb-2" :disabled="!updateAllEnabled"  type="filled" icon="refresh" @click="OpenConfirmUpdateAllJoomlaMotifs">{{$t('MOTIF.UPDATE_ALL_JOOMLA_MOTIFS')}}</vs-button>
            </div> 
            <div class="text-center">
              <vue-dropzone id="motifUploaderDrop" ref="motifUploaderDrop" :options="dropOptions" class="mb-3" v-on:vdropzone-success="dropzoneSuccess" v-on:vdropzone-error="dropzoneError" v-on:vdropzone-sending="dropzoneSending"></vue-dropzone>
            </div>
            <div id="motif_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="motifs"
                :noDataText="$t('MOTIF.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat
                :multiple="true"
                v-model="selectedMotifs">

                <template slot="header">
                  <h3>
                    {{$t('MOTIF.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="name">
                    {{$t('MOTIF.TABLE.COL1')}}
                  </vs-th>
                  <vs-th>{{$t('MOTIF.TABLE.COL2')}}</vs-th>  
                  <vs-th>{{$t('MOTIF.TABLE.COL3')}}</vs-th>
                  <vs-th sort-key="all_img_exist">
                    <vs-icon class="" icon="image"></vs-icon>
                  </vs-th> 
                  <vs-th sort-key="img_preview_data">

                  </vs-th>                                                                                       
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" >   
                    <vs-td :data="tr.name">
                      {{tr.name}}
                    </vs-td>  
                    <vs-td :data="tr.linkedToJoomla">
                      <vs-checkbox :disabled="true" v-model="tr.linkedToJoomla"></vs-checkbox>
                    </vs-td>   
                    <vs-td :data="tr.last_joomla_sync">
                      {{DateToString(tr.last_joomla_sync)}}
                    </vs-td>  
                    <vs-td :data="tr.all_img_exist">
                      <vs-icon class="" :color="tr.all_img_exist == true ? 'success': 'danger'" icon="image"></vs-icon>
                    </vs-td> 
                                 
                    <vs-td :data="tr.img_preview_data">
                        <img style="max-height:50px" v-bind:src="'data:image/jpeg;base64,'+tr.img_preview_data" />
                    </vs-td>                                                                                    
                    <vs-td>
                      <vs-button  @click="EditMotif(tr)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                      <vs-button  @click="OpenConfirmDeleteMotif(tr.id,tr.name)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                      <vs-button  @click="OpenConfirmUpdateJoomlaMotif(tr.id,tr.name)" class="ml-2" size="small" color="gray" type="filled" icon="refresh"></vs-button>
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>   
          </vs-tab>
          <vs-tab :label="$t('MOTIF.TAB_CATEGORIES')" >
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="AddCategory">{{$t('MOTIF.ADD_CAT')}}</vs-button>
            </div> 
            <div id="motifcategory_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="categories"
                :noDataText="$t('MOTIF.CAT.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('MOTIF.CAT.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="name">
                    {{$t('MOTIF.CAT.TABLE.COL1')}}
                  </vs-th>
                                                                                                           
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" >   
                    <vs-td :data="tr.name">
                      {{tr.name}}
                    </vs-td>                  
                                                                                                              
                    <vs-td>
                      <vs-button  @click="EditCategory(tr)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                      <vs-button  @click="OpenConfirmDeleteCategory(tr.id,tr.name)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>   
          </vs-tab>
        </vs-tabs>        
        </vs-card>
      </vs-col>
  </vs-row>

</div>
</template>

<script>

import helperMethods from '../helper/staticFuncHelper';
import enumHelper from '../helper/enumHelper';
import codeHelper from '../helper/staticCodeHelper';
import AddMotifDlg from './components/dialogs/AddMotifDlg.vue';
import AddMotifcategoryDlg from './components/dialogs/AddMotifcategoryDlg.vue';
import vueDropzone from "vue2-dropzone";
import LocalStorageService from '../services/localstorage.service';

const localStorageService = LocalStorageService.getService();

export default {
  name: "MotifAdmin",
  components: {
    AddMotifDlg,
    AddMotifcategoryDlg,
    vueDropzone
  },
    data: function (){
      return {
      motifs:[],
      categories:[],
      selectedMotifs:[],
      dropOptions: {
        url: this.$appConfig.apiBaseUrl+'/motifs/motif/upload',
        maxFilesize: 1, // MB
        maxFiles: 1,
        chunking: false,
        chunkSize: 500, // Bytes
        thumbnailWidth: 150, // px
        thumbnailHeight: 150,
        addRemoveLinks: true,
        headers:{"Authorization":'Bearer ' + localStorageService.getAuthToken()},
        acceptedFiles:".zip",
        forceFallback:false,
        dictDefaultMessage:this.$t('DROPZONE.DEFAULTTEXT'),
        dictFallbackMessage: this.$t('DROPZONE.NOTSUPPORTED'),
        dictFallbackText: this.$t('DROPZONE.FALLBACK_TEXT'),
        dictFileTooBig:this.$t('DROPZONE.TOO_BIG'),
        dictInvalidFileType:this.$t('DROPZONE.INVALID_TYPE'),
        dictResponseError:this.$t('DROPZONE.RESPONSE_ERROR'),
        dictCancelUpload:this.$t('DROPZONE.CANCEL_UPLOAD'),
        dictUploadCanceled:this.$t('DROPZONE.UPLOAD_CANCELED'),
        dictCancelUploadConfirmation:this.$t('DROPZONE.CONFIRM_CANCEL'),
        dictRemoveFile:this.$t('DROPZONE.REMOVE_FILE'),
        dictRemoveFileConfirmation:this.$t('DROPZONE.CONFIRM_REMOVE_FILE'),
        dictMaxFilesExceeded:this.$t('DROPZONE.MAX_FILES')
      },
      };
  },
  mounted () {
      this.LoadData();
    },
  computed: 
  { 
    getTableData() {
      var data = [];
      if(this.$store.state.motif.motifs.data != null)
      {
        data = this.$store.state.motif.motifs.data
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.motif.motifs.status) != 'undefined')
        loading = this.$store.state.motif.motifs.status.loading;
      return loading;
    },
    getCatTableData() {
      var data = [];
      if(this.$store.state.motif.motifcategories.data != null)
      {
        data = this.$store.state.motif.motifcategories.data
      }
      return data;
    },
    loadingCatTableData () {
      var loading = false;
      if(typeof(this.$store.state.motif.motifcategories.status) != 'undefined')
        loading = this.$store.state.motif.motifcategories.status.loading;
      return loading;
    },
    getUserId()
    {
      if(this.$store.state.users.userfull.userdata != null)
        return this.$store.state.users.userfull.userdata.user_fk;
      else
        return "";
    },
    updateAllEnabled()
    {
      return this.selectedMotifs.length > 0;
    }
  },
  watch: {
      getTableData(value) {
          this.motifs = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#motif_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#motif_table'});
        }
      },
      getCatTableData(value) {
          this.categories = value;
      },
      loadingCatTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#motifcategory_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#motifcategory_table'});
        }
      },
    },
  methods: {    
      ...helperMethods,
      ...enumHelper,
      ...codeHelper,
      LoadData: function ()
      {
          this.$store.dispatch('motif/getMotifs'); 
          this.$store.dispatch('motif/getMotifCategories');
      },
      EditMotif(data){
        this.$refs.addMotifDlg.InitDlgData(data);
      },
      AddMotif(){
        this.$refs.addMotifDlg.ShowDlg();
      },
      EditCategory(data){
        this.$refs.addMotifcategoryDlg.InitDlgData(data);
      },
      AddCategory(){
        this.$refs.addMotifcategoryDlg.ShowDlg();
      },
      OpenConfirmDeleteMotif(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('MOTIF.QUESTION.DELETE').replace("*1*",name),
          accept: this.SendDeleteRequest,
          parameters:{"id": id,"name": name}
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('motif/deleteMotif', parameters.id)
        .then(response => {
          if(response.success == true)
          {
            this.LoadData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('MOTIF.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
            var text = this.$t('MOTIF.WARNING.DELETE').replace("*1*",parameters.name);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MOTIF.ERROR.DELETE').replace("*1*",error)});  
        });
      },
      OpenConfirmDeleteCategory(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('MOTIF.CAT.QUESTION.DELETE').replace("*1*",name),
          accept: this.SendDeleteCategoryRequest,
          parameters:{"id": id,"name": name}
        });
      
      },
      SendDeleteCategoryRequest: function(parameters)
      {
        this.$store.dispatch('motif/deleteMotifCategory', parameters.id)
        .then(response => {
          if(response.success == true)
          {
            this.LoadData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('MOTIF.CAT.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
            var text = this.$t('MOTIF.CAT.WARNING.DELETE').replace("*1*",parameters.name);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MOTIF.CAT.ERROR.DELETE').replace("*1*",error)});  
        });
      },
      OpenConfirmUpdateAllJoomlaMotifs(){
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.OK'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('MOTIF.QUESTION.UPDATE_ALL_JOOMLA_MOTIFS'),
          accept: this.SendUpdateAllJoomlaRequest
        });
      
      },

      OpenConfirmUpdateJoomlaMotif(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.OK'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('MOTIF.QUESTION.UPDATE_JOOMLA').replace("*1*",name),
          accept: this.SendUpdateJoomlaRequest,
          parameters:{"id": id,"name": name, "reload":true}
        });
      
      },

      OpenConfirmLoadFromWebsite() {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.OK'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('MOTIF.QUESTION.LOAD_FROM_WEBSITE'),
          accept: this.SendLoadFromWebsiteRequest
        });
      
      },
      
      SendUpdateAllJoomlaRequest()
      {
        this.selectedMotifs.forEach(motif=>{
          let para = {name: motif.name, id:motif.id, reload:false};
          this.SendUpdateJoomlaRequest(para);
        });
      },

      SendLoadFromWebsiteRequest()
      {
        this.$store.dispatch('motif/getMotifsFromWebsite')
        .then(response => {
          if(response.success == true)
          {
            this.LoadData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('MOTIF.SUCCESS.LOAD_FROM_WEBSITE')});  
          }
          else
          {
            var text = this.$t('MOTIF.WARNING.LOAD_FROM_WEBSITE');
            text = text.replace("*1*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MOTIF.ERROR.LOAD_FROM_WEBSITE').replace("*1*",error)});  
        });
      },

      SendUpdateJoomlaRequest: function(parameters)
      {
        this.$store.dispatch('motif/updateJoomlaMotifArticle', parameters.id)
        .then(response => {
          if(response.success == true)
          {
            if(parameters.reload)
            {
              this.LoadData();
            }
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('MOTIF.SUCCESS.UPDATE').replace("*1*",parameters.name)});  
          }
          else
          {
            var text = this.$t('MOTIF.WARNING.UPDATE').replace("*1*",parameters.name);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MOTIF.ERROR.UPDATE').replace("*1*",error)});  
        });
      },
          // eslint-disable-next-line no-unused-vars
      dropzoneSuccess(file, response){
        this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('MOTIF.SUCCESS.UPLOAD_IMG').replace("*1*",file.upload.filename)});
        this.$refs.motifUploaderDrop.removeFile(file);
        this.LoadData();
      },
      // eslint-disable-next-line no-unused-vars
      dropzoneError(file, message, xhr){
        this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MOTIF.ERROR.UPLOAD_IMG').replace("*1*",file.upload.filename)});
        this.$refs.motifUploaderDrop.removeFile(file);
      },
      // eslint-disable-next-line no-unused-vars
      dropzoneSending(file, xhr, formData) {
        formData.append('userid', this.getUserId);
      },
  }
};

</script>