<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddMotifCategory"
      :is-valid="formValid">
        <div>
          <p>{{$t('MOTIF.CAT.ADD_MOTIF.CAT.HEADER.TEXT')}}</p>
          <div class="default-input clearfix align-items-center mb-3">
            <vs-input :label="$t('MOTIF.CAT.ADD.LABEL.NAME')"  class="float-left mr-2 inputx" :placeholder="$t('MOTIF.CAT.ADD.LABEL.NAME')" v-model="data.name"  :danger="name_fail" val-icon-danger="clear"/>
            <vs-select  :label="$t('MOTIF.CAT.ADD.JOOMLA_TAG')" v-model="data.joomla_tag_fk" class="float-left mr-2 inputx">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in joomlaTagsForSelect" />
            </vs-select>
          </div>
          <div class="d-flex align-items-center">
            <vs-select v-model="data.motif_fk" :disabled="selectDisabled">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in motifsForSelect" />
            </vs-select>
            <vs-button :disabled="selectDisabled" class="mt-3 mb-2 ml-3"  type="filled" icon="add" @click="AddMotifToCat"></vs-button>
          </div> 
          <div id="motifcategory_table" class="vs-con-loading__container" >
     
            <vs-table
              search
              :data="data.member"
              :noDataText="$t('MOTIF.CAT_MEM.NO_DATA')"
              stripe
              max-items="5" 
              pagination
              hoverFlat>

              <template slot="header">
                <h4>
                  {{$t('MOTIF.CAT_MEM.TABLE.HEADER')}}
                </h4>
              </template>
              <template slot="thead">                               
                <vs-th sort-key="motif_fk">
                  {{$t('MOTIF.CAT_MEM.TABLE.COL1')}}
                </vs-th>
                                                                                                          
                <vs-th></vs-th>                                                                                                                                             
              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" >   
                  <vs-td :data="tr.motif_fk">
                    {{GetCategoryName(tr.motif_fk)}}                    
                  </vs-td>                  
                                                                                                            
                  <vs-td>
                    <vs-button @click="RemoveMotifFromCat(tr.motif_fk)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                  </vs-td>                    
                                                                                                                                                                                            
                </vs-tr>
              </template>
            </vs-table>
          </div> 
        </div>
      </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';

export default {
  name: "AddMotifcategoryDlg",
  props:[],
  components:{
  },
  data: function (){
     return {
      dlgActive:false,
      popupTitle:this.$t('MOTIF.CAT.ADD.TITLE'),
      name_fail:false,
      name_ok:false,
      data:this.GetEmptyData(),
      selectDisabled:true
     }
  }, 
  mounted () {
    this.ClearForm();
    this.$store.dispatch('motif/getMotifs'); 
    this.$store.dispatch('motif/getJoomlaTags'); 
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      ShowDlg(){
        this.ClearForm();
        this.dlgActive=true;
        this.popupTitle = this.$t('MOTIF.CAT.ADD.TITLE');
        
      },
      closed(){
        this.ClearForm();
      },
      InitDlgData(data){
        
        if(this.IsNotNull(data))
        {
          this.ClearForm();
          this.popupTitle = this.$t('MOTIF.CAT.ADD.TITLE.EDIT');
          
          this.data = JSON.parse(JSON.stringify(data));

          this.dlgActive=true;
        }
      },
      GetCategoryName(id)
      {
        var name = "";
    
        if(this.$store.state.motif.motifs.data != null)
        {
          var motifs = this.$store.state.motif.motifs.data;

          for(var i=0; i < motifs.length; i++)
          {

            if(motifs[i].id == id)
            {
              name = motifs[i].name;
              break;
            }
          }
        }
        return name;
      },
      AddMotifToCat(){
        var motif = {id:0,motif_fk:this.data.motif_fk,motifcategory_fk:this.data.id};
        this.data.member.push(motif);
      },
      RemoveMotifFromCat(id){
        for(var i =0; i < this.data.member.length; i++)
        {
          if(this.data.member[i].motif_fk == id)
          {
            this.data.member.splice(i,1);
            break;
          }
        }
      },
      accepted(){

          this.$store.dispatch('motif/saveMotifCategory', this.data)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('MOTIF.CAT.ADD_CAT.SUCCESS.SAVE')});  
                this.$emit('Success',response.id);
            }
            else
            {
              var text = this.$t(response.text);
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('MOTIF.CAT.ADD_CAT.WARNING.SAVE').replace("*1*",text)}); 
            }
            
          }, 
          error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MOTIF.CAT.ADD_CAT.ERROR.SAVE').replace("*1*",error)});  
          });

          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
        this.data=this.GetEmptyData();
      },
      GetEmptyData(){
        return {
          id:0,
          name:"",
          company_fk:0,
          member:[]
        };
      }
  },
  computed:{
    formValid(){
      return this.name_ok;
    },
    name(){
      return this.data.name;
    },
    motifsForSelect(){
      var retVal = [];

      if(this.$store.state.motif.motifs.data != null)
      {
        var motifs = this.$store.state.motif.motifs.data;

        var actValues = [];

        this.data.member.forEach(mem => {
          actValues.push(mem.motif_fk);
        });
        
        motifs.forEach(motif => {
          if(!actValues.includes(motif.id))
          {
            retVal.push({"text": motif.name, "value":motif.id});
          }
        });
      }
      return retVal;
    },
    joomlaTagsForSelect(){
      var retVal = [];

      if(this.$store.state.motif.joomlatags.data != null)
      {
        var joomlatags = this.$store.state.motif.joomlatags.data;
        
        joomlatags.forEach(tag => {
          var title = tag.title.toLowerCase();
          if(title.startsWith("konvex") == false && title.startsWith("root") == false)
          {
            retVal.push({"text": tag.title, "value":tag.id});
          }
        });
      }
      return retVal;
    }
  },
  watch:{
    name(value)
    {
       this.name_fail = value.length == 0;
       this.name_ok = !this.name_fail;
    },
    motifsForSelect(value)
    {
      this.selectDisabled = this.IsEmpty(value);  
    }
  }
};
</script>
<style>
div#promptAddMotifCategory > div.vs-dialog{
  max-width: 500px !important;
}
div#promptAddMotifCategory > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}
</style>