<template>
  <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddMotif"
      :is-valid="formValid">
      <div>
        <vs-tabs>
          <vs-tab :label="$t('MOTIF.EDIT_MOTIF')" >
            <div>
              <p>{{$t('MOTIF.ADD_MOTIF.HEADER.TEXT')}}</p>
              <div class="default-input clearfix align-items-center mb-3">
                <vs-input :label="$t('MOTIF.ADD.LABEL.NAME')"  class="float-left mr-2 inputx" :placeholder="$t('MOTIF.ADD.LABEL.NAME')" v-model="data.name"  :danger="name_fail" val-icon-danger="clear"/>
                <vs-input :label="$t('MOTIF.ADD.LABEL.DESCRIPTION')"  class="float-left mr-2 inputx" :placeholder="$t('MOTIF.ADD.LABEL.DESCRIPTION')" v-model="data.description" />
                <vs-input :label="$t('MOTIF.ADD.LABEL.ADOBE')"  class="float-left mr-2 inputx" :placeholder="$t('MOTIF.ADD.LABEL.ADOBE')" v-model="data.adobe_img_id" />
              </div>
              <div class="default-input d-flex align-items-center mb-3">
                <vs-select :label="$t('MOTIF.MEMBER.LABEL.COUNTRY')" v-model="data.country_fk" class="mr-2">
                  <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in countries" />
                </vs-select>
                <vs-select :label="$t('MOTIF.MEMBER.LABEL.WAEHRUNG')" v-model="data.currency_fk" class="mr-2">
                  <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in currencies" />
                </vs-select>
                <ProductPicker ref="prodPicker_netto" :label="$t('MOTIF.MEMBER.LABEL.PRICE_OF_PROD')" :isLabelOkay="true" :pricesLastYear="false" :country_fk="data.country_fk" :currency_fk="data.currency_fk" v-model="data.netto_from_product_fk" :withMotifpickerOnly="false"></ProductPicker>
              </div>
              <div class="default-input clearfix align-items-center mb-3">
                <vs-input :label="$t('MOTIF.ADD.LABEL.PATH_H')"  class="float-left mr-2 inputx" :placeholder="$t('MOTIF.ADD.LABEL.PATH_H')" v-model="data.img_preview"/>                
              </div>
              <div class="default-input clearfix align-items-center mb-3">
                <vs-input :label="$t('MOTIF.ADD.LABEL.JOOMLA_IMG_DESCR')"  class="float-left mr-2 inputx" :placeholder="$t('MOTIF.ADD.LABEL.JOOMLA_IMG_DESCR')" v-model="data.joomla_img_description"/>                
              </div>
            </div>
          </vs-tab>
          <vs-tab  :label="$t('MOTIF.ORIENTATION')">
            <div class="d-flex align-items-center">
              <ProductPicker ref="prodPicker" :label="$t('MOTIF.MEMBER.LABEL.PRODUCT')" :isLabelOkay="true" :pricesLastYear="false" :country_fk="0" :currency_fk="0" v-model="orientData.product_fk" :withMotifpickerOnly="true"></ProductPicker> 
              <vs-input :label="$t('MOTIF.MEMBER.LABEL.PATH_H')"  class="float-left mr-2 inputx" :placeholder="$t('MOTIF.MEMBER.LABEL.PATH_H')" v-model="orientData.img_horizontal"/>
              <vs-input :label="$t('MOTIF.MEMBER.LABEL.PATH_V')"  class="float-left mr-2 inputx" :placeholder="$t('MOTIF.MEMBER.LABEL.PATH_V')" v-model="orientData.img_vertical"/>
              <vs-button class="ml-3"  type="filled" icon="add" @click="AddOrientMember" v-show="!orientEditMode" :disabled="!orientAddPossible"></vs-button>
              <vs-button class="ml-3"  type="filled" icon="save" v-show="orientEditMode" @click="SaveOrientMember"></vs-button>
              <vs-button class="ml-1"  type="filled" icon="clear" v-show="orientEditMode" @click="ClearOrient"></vs-button>
            </div> 
            <div class="d-flex align-items-center mb-3 mt-2">
              <vs-checkbox class="" v-model="orientData.horizontal">{{$t('ENUMS.ORIENTATION.HORIZONTAL')}}</vs-checkbox>
              <vs-checkbox class="ml-3" v-model="orientData.vertical">{{$t('ENUMS.ORIENTATION.VERTICAL')}}</vs-checkbox>
            </div>
            <div id="variant_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="data.orientationMember"
                :noDataText="$t('MOTIF.MEMBER.NO_DATA')"
                stripe
                max-items="5" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('MOTIF.MEMBER.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="product_fk">
                    {{$t('MOTIF.MEMBER.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="horizontal">
                    {{$t('MOTIF.MEMBER.TABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="vertical">
                    {{$t('MOTIF.MEMBER.TABLE.COL3')}}
                  </vs-th>  
                  <vs-th></vs-th>                                                  
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr"  :key="'grp_'+indextr" v-for="(tr, indextr) in data" >                    
                    <vs-td :data="tr.product_fk">
                      {{GetBezOfProduct(tr.product_fk)}}
                    </vs-td>      
                    <vs-td :data="tr.horizontal">
                      <vs-checkbox :disabled="true" v-model="tr.horizontal"></vs-checkbox>
                    </vs-td>  
                    <vs-td :data="tr.vertical">
                      <vs-checkbox :disabled="true" v-model="tr.vertical"></vs-checkbox>
                    </vs-td> 
                    <vs-td :data="tr.img_ok">
                      <vs-icon class="" :color="tr.img_ok == true ? 'success': 'danger'" icon="image"></vs-icon>
                    </vs-td>                                                    
                    <vs-td>
                      <vs-button  @click="EditOrientMember(tr)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                      <vs-button  @click="RemoveOrientMember(tr.id)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vs-prompt>
  </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';
import ProductPicker from '../ProductPicker.vue';

export default {
  name: "AddMotifDlg",
  props:[],
  components:{
    ProductPicker
  },
  data: function (){
     return {
      dlgActive:false,
      popupTitle:this.$t('MOTIF.ADD_MOTIF.TITLE'),
      name_fail:false,
      name_ok:false,
      data:this.GetEmptyData(),
      products:[],
      orientData:{product_fk:0},
      orientEditMode:false
     }
  }, 
  mounted () {
    this.ClearForm();
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      ShowDlg(){
        this.ClearForm();
        this.dlgActive=true;
        this.popupTitle = this.$t('MOTIF.ADD_MOTIF.TITLE');
        
      },
      closed(){
        this.ClearForm();
      },
      InitDlgData(data){
        
        if(this.IsNotNull(data))
        {
          this.ClearForm();
          this.popupTitle = this.$t('MOTIF.ADD_MOTIF.TITLE.EDIT');
          
          this.data = JSON.parse(JSON.stringify(data));

          this.dlgActive=true;
        }
      },
      accepted(){
          this.data.netto = Number(this.data.netto);
          this.$store.dispatch('motif/saveMotif', this.data)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('MOTIF.ADD_MOTIF.SUCCESS.SAVE')});  
                this.$emit('Success',response.id);
            }
            else
            {
              var text = this.$t(response.text);
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('MOTIF.ADD_MOTIF.WARNING.SAVE').replace("*1*",text)}); 
            }
            
          }, 
          error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MOTIF.ADD_MOTIF.ERROR.SAVE').replace("*1*",error)});  
          });

          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
        this.data=this.GetEmptyData();
        this.ClearOrient();
      },
      GetEmptyData(){
        return {
          id:0,
          name:"",
          img_path_hori:"",
          img_path_vert:"",
          company_fk:0,
          description:"",
          netto:0,
          isEigen:false,
          orientationMember:[],
          adobe_img_id:"",
          joomla_img_description:""
        };
      },
      ClearOrient(){
        this.orientData = {id:0,motif_fk:0, product_fk:0, horizontal:false,vertical:false,img_vertical:"",img_horizontal:""};
        this.orientEditMode = false;
      },
      AddOrientMember(){
          this.orientData.motif_fk = this.data.id;
          this.data.orientationMember.push(this.orientData);
          this.ClearOrient();
      },
      EditOrientMember(data){
        this.orientData.id = data.id;
        this.orientData.product_fk = data.product_fk;
        this.orientData.motif_fk = data.motif_fk;
        this.orientData.horizontal = data.horizontal;
        this.orientData.vertical = data.vertical;
        this.orientData.img_vertical = data.img_vertical;
        this.orientData.img_horizontal = data.img_horizontal;

        this.orientEditMode = true;
        this.SetProductId(data.product_fk);
      },
      SaveOrientMember(){
        for(var i = 0; i < this.data.orientationMember.length; i++)
        {
          if(this.orientData.id == this.data.orientationMember[i].id)
          {
            this.data.orientationMember[i].id = this.orientData.id;
            this.data.orientationMember[i].product_fk = this.orientData.product_fk;
            this.data.orientationMember[i].motif_fk = this.orientData.motif_fk;
            this.data.orientationMember[i].horizontal = this.orientData.horizontal;
            this.data.orientationMember[i].vertical = this.orientData.vertical;
            this.data.orientationMember[i].img_vertical = this.orientData.img_vertical;
            this.data.orientationMember[i].img_horizontal = this.orientData.img_horizontal;

            this.ClearOrient();
            break;
          }
        }
        
      },
      RemoveOrientMember(id){
        for(var i = 0; i < this.data.orientationMember.length; i++)
        {
          if(this.data.orientationMember[i].id == id)
          {
            this.data.orientationMember.splice(i, 1);

            break;
          }
        }
      },
      SetProductId(id){
        this.$refs.prodPicker.SetSelectedProd(id);
      },


  },
  computed:{
    formValid(){
      return this.name_ok;
    },
    name(){
      return this.data.name;
    },
    orientAddPossible(){
      return this.orientData.product_fk > 0 && (this.orientData.horizontal == true || this.orientData.vertical == true);
    },
    countries(){
      return this.GetListOfCountriesForSelect(false);
    },
    currencies(){    
      return this.GetListOfCurrenciesForSelect(false);
    },
  },
  watch:{
    name(value)
    {
       this.name_fail = value.length == 0;
       this.name_ok = !this.name_fail;
    }
  }
};
</script>
<style>
div#promptAddMotif > div.vs-dialog{
  max-width: 800px !important;
}
div#promptAddMotif > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}
</style>